import { default as confirmudUzOlg49GMeta } from "/home/runner/work/qtp/qtp/pages/confirm.vue?macro=true";
import { default as continue0HLQ0PVuHVMeta } from "/home/runner/work/qtp/qtp/pages/continue.vue?macro=true";
import { default as finishM94uT1mzR1Meta } from "/home/runner/work/qtp/qtp/pages/finish.vue?macro=true";
import { default as index6WUVDapE4KMeta } from "/home/runner/work/qtp/qtp/pages/index.vue?macro=true";
import { default as quizUSFXFAHbHbMeta } from "/home/runner/work/qtp/qtp/pages/quiz.vue?macro=true";
import { default as registerx1IGbZ4vx2Meta } from "/home/runner/work/qtp/qtp/pages/register.vue?macro=true";
export default [
  {
    name: "confirm",
    path: "/confirm",
    component: () => import("/home/runner/work/qtp/qtp/pages/confirm.vue").then(m => m.default || m)
  },
  {
    name: "continue",
    path: "/continue",
    component: () => import("/home/runner/work/qtp/qtp/pages/continue.vue").then(m => m.default || m)
  },
  {
    name: "finish",
    path: "/finish",
    component: () => import("/home/runner/work/qtp/qtp/pages/finish.vue").then(m => m.default || m)
  },
  {
    name: "index",
    path: "/",
    component: () => import("/home/runner/work/qtp/qtp/pages/index.vue").then(m => m.default || m)
  },
  {
    name: "quiz",
    path: "/quiz",
    component: () => import("/home/runner/work/qtp/qtp/pages/quiz.vue").then(m => m.default || m)
  },
  {
    name: "register",
    path: "/register",
    component: () => import("/home/runner/work/qtp/qtp/pages/register.vue").then(m => m.default || m)
  }
]